let protocol = 'https';
let isPreview = document.location.hostname.match(/\.preview\.infomaniak/);

const urls = {
  develop: ['smng-website-1x.localhost'],
  staging: ['lpawaqsic.preview.infomaniak.website','staging.schmidt-nagel.ch'],
  production: ['schmidt-nagel.ch']
}

// handle source from environment variables
let frontendHostname, backendHostname, cacheHostname, apiHostname;
let source = process.env.REACT_APP_SOURCE;
if (!source) source = urls.develop.includes(document.location.hostname) ? 'develop' : urls.staging.includes(document.location.hostname) ? 'staging' : 'production';

switch(source) {
  case 'staging':
    protocol = 'https';
    if (isPreview) {
      frontendHostname = 'lpawaqsic.preview.infomaniak.website';
      backendHostname = 'lpawaqtbt.preview.infomaniak.website';
      cacheHostname = 'lpawaqtcb.preview.infomaniak.website';
      apiHostname = 'lpawaqtbx.preview.infomaniak.website';
    } else {
      frontendHostname = 'staging.schmidt-nagel.ch';
      backendHostname = `backend.staging.schmidt-nagel.ch`;
      cacheHostname = `cache.staging.schmidt-nagel.ch`;
      apiHostname = `api.staging.schmidt-nagel.ch`;
    }
  break;
  case 'develop':
    protocol = 'https';
    backendHostname = `backend.schmidt-nagel.ch`;
    cacheHostname = `cache.schmidt-nagel.ch`;
    apiHostname = `api.schmidt-nagel.ch`;
  break;
  case 'production':
  default:
    protocol = 'https';
    frontendHostname = 'schmidt-nagel.ch';
    backendHostname = `backend.schmidt-nagel.ch`;
    cacheHostname = `cache.schmidt-nagel.ch`;
    apiHostname = `api.schmidt-nagel.ch`;
  break;
}

const Settings = {
  supportedLanguages: ['fr', 'en', 'de', 'it'],
  defaultLanguage: 'en',
  descriptionAllowed: true,
  indicationAllowed: false,
  posologyAllowed: false,
  topicAllowed: false,
  checkoutUrl: `${protocol}://${backendHostname}/add_to_cart/`,
  LogTranslations: false,
  readMore: 'Learn More',
  version: '1.0.4',
  // also change url in package.json & .htaccess
  server: `${protocol}://${frontendHostname}`,
  baseName: '/',
  // also change url in package.json & .htaccess
  apiBase: `${protocol}://${backendHostname}/wp-json`,
  cachedApiBase: `${protocol}://${cacheHostname}/`,
  apiDomain: 'sn',
  apiBaseDirectus: `${protocol}://${apiHostname}/_`,
  useCache: true,
  linkCanada: `https://www.homeodel.com`,
  linkFrance: `https://www.schmidt-nagel.fr/`,
  shopLink: `https://shop.schmidt-nagel.ch/login`,
  siteName: 'Laboratoire homéopathique Schmidt-Nagel',
  // cost for shipping in CHF
  costShipping: 4.31,
  primaryColor: '#64b246',
}

export default Settings;
