// Google Analytics 4 Setup
export const GA_TRACKING_ID = 'G-J4CYSENJN6';

// Initialize Google Analytics
export const initGA = () => {
  if (typeof window !== 'undefined') {
    // Add Google Analytics script only if it doesn't exist
    if (!window.gtag) {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GA_TRACKING_ID}');
      `;
      
      document.head.appendChild(script1);
      document.head.appendChild(script2);
    }
  }
};

// Track page views
export const trackPage = (page) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: page,
    });
  }
};

// Track events
export const trackEvent = (action, category, label, value) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};
