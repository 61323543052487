import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Header, Segment, Divider } from "semantic-ui-react";
import _t from "../utils/translate";

// Convertir en composant fonctionnel pour utiliser useEffect
const SignupConfirmation = ({ language }) => {
  useEffect(() => {
    // Ajouter le script Google Analytics
    const gtagScript1 = document.createElement("script");
    gtagScript1.async = true;
    gtagScript1.src =
      "https://www.googletagmanager.com/gtag/js?id=G-J4CYSENJN6";
    document.head.appendChild(gtagScript1);

    const gtagScript2 = document.createElement("script");
    gtagScript2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-J4CYSENJN6');
    `;
    document.head.appendChild(gtagScript2);

    // Nettoyage au démontage du composant
    return () => {
      document.head.removeChild(gtagScript1);
      document.head.removeChild(gtagScript2);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "70vh",
      }}
    >
      <Container text style={{ margin: "auto" }}>
        <Segment padded="very" textAlign="center">
          <Header as="h2">
            {_t(
              "Thank you for signing up. We'll inform you once your account has been activated."
            )}
          </Header>

          <Divider hidden />

          <p>
            {_t(
              "You will receive an email once your account has been verified and activated."
            )}
          </p>

          <Divider hidden />

          <Link to={`/${language}/`}>{_t("Back to Home")}</Link>
        </Segment>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.ui.language,
});

export default connect(mapStateToProps)(SignupConfirmation);
