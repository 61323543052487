import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Message, Grid } from "semantic-ui-react";
import Validator from "validator";
import { connect } from "react-redux";
import $ from "jquery";
import { withRouter } from "react-router-dom";
// import Select from 'react-select'
import _ from "lodash";
import LogRocket from "logrocket";

import InlineError from "../messages/InlineError";
import { createNewUser } from "../actions/userActions";
import { showMessage } from "../actions/uiActions";

import _t from "../utils/translate";
import CountryCodes from "../utils/CountryCodes";

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        profession: "none",
        pharmaId: "",
        firstName: "",
        lastName: "",
        email: "",
        repeatEmail: "",
        password: "",
        repeatPassword: "",
        billingFirstName: "",
        billingLastName: "",
        billingCompany: "",
        billingPhone: "",
        billingAddress1: "",
        billingAddress2: "",
        billingCity: "",
        billingCountry: "CH",
        billingPostcode: "",
        billingState: "",
        shippingFirstName: "",
        shippingLastName: "",
        shippingAddress1: "",
        shippingAddress2: "",
        shippingCity: "",
        shippingCountry: "CH",
        shippingCompany: "",
        shippingPostcode: "",
        shippingState: "",
      },
      touched: {
        profession: false,
        pharmaId: false,
        firstName: false,
        lastName: false,
        email: false,
        repeatEmail: false,
        password: false,
        repeatPassword: false,
        billingFirstName: false,
        billingLastName: false,
        billingCompany: false,
        billingPhone: false,
        billingAddress1: false,
        billingAddress2: false,
        billingCity: false,
        billingCountry: false,
        billingPostcode: false,
        billingState: false,
        shippingFirstName: false,
        shippingLastName: false,
        shippingAddress1: false,
        shippingAddress2: false,
        shippingCity: false,
        shippingCountry: false,
        shippingCompany: false,
        shippingPostcode: false,
        shippingState: false,
      },
      certificateHover: false,
      shippingSame: true,
      loading: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.syncronizeShipping = this.syncronizeShipping.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.billingCountrySelect = this.billingCountrySelect.bind(this);
    this.shippingCountrySelect = this.shippingCountrySelect.bind(this);
    this.changeCertificate = this.changeCertificate.bind(this);

    this.fileInputRef = React.createRef();
  }

  billingCountrySelect = (e) => {
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        billingCountry: e.value,
      },
      errors: { ...oldState.errors, billingCountry: false },
    }));
    this.syncronizeShipping();
  };

  changeCertificate = (value) => {
    this.setState({ certificateHover: value });
  };

  shippingCountrySelect = (r, e) => {
    // e.persist()
    console.log(e);
    this.setState((oldState) => ({
      data: {
        ...oldState.data,
        shippingCountry: e.value,
      },
      errors: { ...oldState.errors, shippingCountry: false },
    }));
    this.syncronizeShipping();
  };

  onChange = (e) => {
    e.persist();
    const {
      state: { data, shippingSame },
    } = this;
    let newData = { ...data, [e.target.name]: e.target.value };
    if (shippingSame) {
      newData.shippingAddress1 = newData.billingAddress1;
    }
    this.setState(
      (oldState) => ({
        data: {
          ...oldState.data,
          ...newData,
        },
        touched: {
          ...oldState.touched,
          [e.target.name]: true,
        },
        errors: { ...oldState.errors, [e.target.name]: false },
      }),
      () => {}
    );

    this.syncronizeShipping();
  };

  fileChange = (e) => {
    e.persist();
    // console.log(e.target.files[0])
    this.setState((oldState) => ({
      data: { ...oldState.data, certificate: e.target.files[0] },
      errors: { ...oldState.errors, certificate: null },
    }));
  };

  onSubmit = () => {
    const { createNewUser, showMessage, history, language } = this.props;
    const { data } = this.state;
    const errors = this.validate(data);
    // const errors = {}
    this.setState({ errors });
    if (Object.keys(errors).length !== 0) {
      showMessage(
        _t("There are errors in the form, please check and try again."),
        "error"
      );
      window.setTimeout(function() {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(".error").offset().top - 160,
          },
          1000
        );
      }, 3000);
    }
    if (Object.keys(errors).length === 0) {
      if (!window.location.href.includes("localhost")) {
        LogRocket.identify(data?.email);
      }
      this.setState({ loading: true });
      createNewUser(this.composeFormData())
        .then(
          (res) => {
            this.setState({ loading: false });
            // Supprimez cette ligne qui déclenche le pop-up
            // showMessage(_t(res.data.message), 'success')

            // Redirection vers la page de confirmation au lieu de la page d'accueil
            history.push(`/${language}/signup-confirmation`);
            return res;
          },
          (err) => {
            this.setState({ loading: false });
            showMessage(_t(err.response.data.message), "error");
          }
        )
        .catch((err) => {
          this.setState({ loading: false });
          this.setState({
            errors: { global: "There is a problem" } || {},
            loading: false,
          });
        });
    }
  };

  composeFormData = () => {
    const { data } = this.state;
    const { language } = this.props;
    const formData = new FormData();
    Object.keys({ ...data }).map((key) => {
      formData.append(key, data[key]);
      return null;
    });
    formData.append("language", language);
    return formData;
  };

  validate = (data) => {
    const errors = {};
    if (!Validator.isEmail(data.email)) errors.email = _t("Invalid email");
    if (data.email !== data.repeatEmail)
      errors.repeatEmail = _t("Emails are not the same");
    if (!data.password) errors.password = _t("Can't be blank");
    if (data.password !== data.repeatPassword)
      errors.repeatPassword = _t("Passwords are not the same");
    if (data.password.length < 8)
      errors.password = _t(
        "Your password has to be at least eight characters long"
      );
    if (!data.firstName) errors.firstName = _t("Can't be blank");
    if (!data.lastName) errors.lastName = _t("Can't be blank");

    if (!data.billingFirstName) errors.billingFirstName = _t("Can't be blank");
    if (!data.billingPhone) errors.billingPhone = _t("Can't be blank");
    if (!data.billingLastName) errors.billingLastName = _t("Can't be blank");
    if (!data.billingAddress1) errors.billingAddress1 = _t("Can't be blank");
    if (!data.billingCity) errors.billingCity = _t("Can't be blank");
    if (!data.billingCountry) errors.billingCountry = _t("Can't be blank");
    if (!data.billingPostcode) errors.billingPostcode = _t("Can't be blank");
    if (data.profession === "none")
      errors.profession = _t("You can only sign up as a professional.");
    if (data.profession === "pharmacist" && data.pharmaId === "")
      errors.pharmaId = _t("Please specify your Pharma Id.", "professions");
    // if (!data.billingState) errors.billingState = _t("Can't be blank")

    if (!data.certificate)
      errors.certificate = _t("Please select a certificate.");

    return errors;
  };

  toggleShipping = () => {
    this.setState((oldState) => ({
      shippingSame: !oldState.shippingSame,
    }));
    this.syncronizeShipping();
  };

  syncronizeShipping = () => {
    this.setState((oldState) => {
      let newBilling = {};
      if (!oldState.touched.billingFirstName) {
        newBilling.billingFirstName = oldState.data.firstName;
        if (oldState.shippingSame) {
          newBilling.shippingFirstName = oldState.data.firstName;
        }
      }
      if (!oldState.touched.billingLastName) {
        newBilling.billingLastName = oldState.data.lastName;
        if (oldState.shippingSame) {
          newBilling.shippingLastName = oldState.data.lastName;
        }
      }

      if (oldState.shippingSame) {
        return {
          data: {
            ...oldState.data,
            shippingFirstName: oldState.data.billingFirstName,
            shippingLastName: oldState.data.billingLastName,
            shippingAddress1: oldState.data.billingAddress1,
            shippingAddress2: oldState.data.billingAddress2,
            shippingCity: oldState.data.billingCity,
            shippingCountry: oldState.data.billingCountry,
            shippingCompany: oldState.data.billingCompany,
            shippingPostcode: oldState.data.billingPostcode,
            shippingState: oldState.data.billingState,
            ...newBilling,
          },
        };
      }

      return {
        data: {
          ...oldState.data,
          ...newBilling,
        },
      };
    });
  };

  render() {
    const {
      data,
      errors,
      loading,
      shippingSame,
      certificateHover,
    } = this.state;
    const {
      toggleShipping,
      changeCertificate,
      props: { options, language },
    } = this;
    // const errors = {}

    // console.log(this.state)

    const signupOptions = options?.[language]?.signup;

    const CountriesUnsorted = [];
    CountryCodes.map((element) => {
      CountriesUnsorted.push({
        value: element.value,
        label: _t(element.label, "countries"),
      });
      return null;
    });

    const Countries = _.sortBy(CountriesUnsorted, "label");

    let certificateLabel = data.certificate
      ? data.certificate.name
      : _t("Choose File");

    if (certificateHover && data.certificate) {
      certificateLabel = _t("Change File");
    }
    return (
      <Form className="singup_form" onSubmit={this.onSubmit} loading={loading}>
        {errors.global && (
          <Message negative>
            <Message.Header>Something went wrong</Message.Header>
            <p>{errors.global}</p>
          </Message>
        )}
        <p className="align_center only_professional">
          {signupOptions?.professional_text ||
            _t(
              "Please note, that by law we are not allowed to sell our products directly to end customers. Therefore Sign Up is only available to swiss therapists with practice authorization from the canton. This has to be proven by uploading a document that verifies you as a professional."
            )}
        </p>
        <h2>{_t("Profession")}</h2>
        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.profession}>
              <input
                type="text"
                name="profession"
                onChange={this.onChange}
                placeholder={_t("Please enter your profession")}
              />
              {errors.profession && (
                <InlineError text={_t(errors.profession, "professions")} />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid>
        {data.profession === "pharmacist" && (
          <Grid>
            <Grid.Column>
              <Form.Field error={!!errors.pharmaId}>
                <input
                  type="text"
                  id="pharmaId"
                  name="pharmaId"
                  placeholder={_t("Pharma Id*")}
                  value={data.pharmaId}
                  onChange={this.onChange}
                />

                {errors.pharmaId && <InlineError text={errors.pharmaId} />}
              </Form.Field>
            </Grid.Column>
          </Grid>
        )}
        <h2>{_t("General")}</h2>
        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.firstName}>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder={_t("First Name")}
                autoComplete="given-name"
                value={data.firstName}
                onChange={this.onChange}
              />

              {errors.firstName && <InlineError text={errors.firstName} />}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field error={!!errors.lastName}>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder={_t("Last Name")}
                autoComplete="family-name"
                value={data.lastName}
                onChange={this.onChange}
              />

              {errors.lastName && <InlineError text={errors.lastName} />}
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.email}>
              <input
                type="email"
                id="email"
                name="email"
                placeholder={_t("Email")}
                autoComplete="email"
                value={data.email}
                onChange={this.onChange}
              />

              {errors.email && <InlineError text={errors.email} />}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field error={!!errors.repeatEmail}>
              <input
                type="email"
                id="repeatEmail"
                name="repeatEmail"
                placeholder={_t("Repeat Email")}
                autoComplete="email"
                value={data.repeatEmail}
                onChange={this.onChange}
              />

              {errors.repeatEmail && <InlineError text={errors.repeatEmail} />}
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.password}>
              <input
                type="password"
                id="password"
                name="password"
                placeholder={_t("Password")}
                value={data.password}
                autoComplete="new-password"
                onChange={this.onChange}
              />
              {errors.password && <InlineError text={errors.password} />}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field error={!!errors.repeatPassword}>
              <input
                type="password"
                id="repeatPassword"
                name="repeatPassword"
                placeholder={_t("Repeat Password")}
                value={data.repeatPassword}
                autoComplete="new-password"
                onChange={this.onChange}
              />
              {errors.repeatPassword && (
                <InlineError text={errors.repeatPassword} />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.billingPhone}>
              <input
                type="tel"
                id="billingPhone"
                name="billingPhone"
                placeholder={_t("Phone number")}
                value={data.billingPhone}
                autoComplete="tel"
                onChange={this.onChange}
              />
              {errors.billingPhone && (
                <InlineError text={errors.billingPhone} />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid>
        <h2>{_t("Billing Address")}</h2>

        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.billingFirstName}>
              <input
                type="text"
                id="billingFirstName"
                name="billingFirstName"
                placeholder={_t("First Name")}
                autoComplete="given-name"
                value={data.billingFirstName}
                onChange={this.onChange}
              />

              {errors.billingFirstName && (
                <InlineError text={errors.billingFirstName} />
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field error={!!errors.billingLastName}>
              <input
                type="text"
                id="billingLastName"
                name="billingLastName"
                placeholder={_t("Last Name")}
                autoComplete="given-name"
                value={data.billingLastName}
                onChange={this.onChange}
              />

              {errors.billingLastName && (
                <InlineError text={errors.billingLastName} />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid>

        <Form.Field error={!!errors.billingCompany}>
          <input
            type="text"
            id="billingCompany"
            name="billingCompany"
            placeholder={_t("Company")}
            value={data.billingCompany}
            onChange={this.onChange}
          />
          {errors.billingCompany && (
            <InlineError text={errors.billingCompany} />
          )}
        </Form.Field>
        <Form.Field error={!!errors.billingAddress1}>
          <input
            type="text"
            id="billingAddress1"
            name="billingAddress1"
            placeholder={_t("Address 1")}
            value={data.billingAddress1}
            onChange={this.onChange}
          />
          {errors.billingAddress1 && (
            <InlineError text={errors.billingAddress1} />
          )}
        </Form.Field>
        <Form.Field error={!!errors.billingAddress2}>
          <input
            type="text"
            id="billingAddress2"
            name="billingAddress2"
            placeholder={_t("Address 2")}
            value={data.billingAddress2}
            onChange={this.onChange}
          />
          {errors.billingAddress2 && (
            <InlineError text={errors.billingAddress2} />
          )}
        </Form.Field>

        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.billingPostcode}>
              <input
                type="billingPostcode"
                id="billingPostcode"
                name="billingPostcode"
                placeholder={_t("Postcode")}
                value={data.billingPostcode}
                onChange={this.onChange}
              />
              {errors.billingPostcode && (
                <InlineError text={errors.billingPostcode} />
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field error={!!errors.billingCity}>
              <input
                type="billingCity"
                id="billingCity"
                name="billingCity"
                placeholder={_t("City")}
                value={data.billingCity}
                onChange={this.onChange}
              />
              {errors.billingCity && <InlineError text={errors.billingCity} />}
            </Form.Field>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column>
            <Form.Field error={!!errors.billingState}>
              <input
                type="billingState"
                id="billingState"
                name="billingState"
                placeholder={_t("State")}
                value={data.billingState}
                onChange={this.onChange}
              />
              {errors.billingState && (
                <InlineError text={errors.billingState} />
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field error={!!errors.billingCountry}>
              <select
                name="billingCountry"
                onChange={this.onChange}
                value={data.billingCountry}
              >
                {Countries.map((element) => (
                  <option key={element.value} value={element.value}>
                    {element.label}
                  </option>
                ))}
              </select>
              {errors.billingCountry && (
                <InlineError text={errors.billingCountry} />
              )}
            </Form.Field>
          </Grid.Column>
        </Grid>

        <h2>{_t("Shipping Address")}</h2>

        <Button onClick={toggleShipping} type="button">
          {shippingSame
            ? _t("Shipping Address is different")
            : _t("Shipping Address is the same")}
        </Button>
        <div className={`shipping_address${shippingSame ? "" : " open"}`}>
          <Grid>
            <Grid.Column>
              <Form.Field error={!!errors.shippingFirstName}>
                <input
                  type="text"
                  id="shippingFirstName"
                  name="shippingFirstName"
                  placeholder={_t("First Name")}
                  autoComplete="given-name"
                  value={data.shippingFirstName}
                  onChange={this.onChange}
                />

                {errors.shippingFirstName && (
                  <InlineError text={errors.shippingFirstName} />
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field error={!!errors.shippingLastName}>
                <input
                  type="text"
                  id="shippingLastName"
                  name="shippingLastName"
                  placeholder={_t("Last Name")}
                  autoComplete="given-name"
                  value={data.shippingLastName}
                  onChange={this.onChange}
                />

                {errors.shippingLastName && (
                  <InlineError text={errors.shippingLastName} />
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>
          <Form.Field error={!!errors.shippingCompany}>
            <input
              type="text"
              id="shippingCompany"
              name="shippingCompany"
              placeholder={_t("Company")}
              value={data.shippingCompany}
              onChange={this.onChange}
            />
            {errors.shippingCompany && (
              <InlineError text={errors.shippingCompany} />
            )}
          </Form.Field>
          <Form.Field error={!!errors.shippingAddress1}>
            <input
              type="text"
              id="shippingAddress1"
              name="shippingAddress1"
              placeholder={_t("Address 1")}
              value={data.shippingAddress1}
              onChange={this.onChange}
            />
            {errors.shippingAddress1 && (
              <InlineError text={errors.shippingAddress1} />
            )}
          </Form.Field>
          <Form.Field error={!!errors.shippingAddress2}>
            <input
              type="text"
              id="shippingAddress2"
              name="shippingAddress2"
              placeholder={_t("Address 2")}
              value={data.shippingAddress2}
              onChange={this.onChange}
            />
            {errors.shippingAddress2 && (
              <InlineError text={errors.shippingAddress2} />
            )}
          </Form.Field>
          <Grid>
            <Grid.Column>
              <Form.Field error={!!errors.shippingPostcode}>
                <input
                  type="shippingPostcode"
                  id="shippingPostcode"
                  name="shippingPostcode"
                  placeholder={_t("Postcode")}
                  value={data.shippingPostcode}
                  onChange={this.onChange}
                />
                {errors.shippingPostcode && (
                  <InlineError text={errors.shippingPostcode} />
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field error={!!errors.shippingCity}>
                <input
                  type="shippingCity"
                  id="shippingCity"
                  name="shippingCity"
                  placeholder={_t("City")}
                  value={data.shippingCity}
                  onChange={this.onChange}
                />
                {errors.shippingCity && (
                  <InlineError text={errors.shippingCity} />
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Grid>
            <Grid.Column>
              <Form.Field error={!!errors.shippingState}>
                <input
                  type="shippingState"
                  id="shippingState"
                  name="shippingState"
                  placeholder={_t("State")}
                  value={data.shippingState}
                  onChange={this.onChange}
                />
                {errors.shippingState && (
                  <InlineError text={errors.shippingState} />
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field error={!!errors.shippingCountry}>
                <select
                  name="shippingCountry"
                  onChange={this.onChange}
                  value={data.shippingCountry}
                >
                  {Countries.map((element) => (
                    <option key={element.value} value={element.value}>
                      {element.label}
                    </option>
                  ))}
                </select>

                {errors.shippingCountry && (
                  <InlineError text={errors.shippingCountry} />
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>
        </div>
        <h2>{_t("Certificate")}</h2>
        <Button
          type="button"
          labelPosition="left"
          className={`upload_button ${errors.certificate ? "error" : ""}`}
          onClick={() => this.fileInputRef.current.click()}
          onMouseEnter={() => changeCertificate(true)}
          onMouseLeave={() => changeCertificate(false)}
        >
          <span className="visible">{certificateLabel}</span>
          <span className="hidden">
            {data.certificate ? data.certificate.name : _t("Choose File")}
          </span>
        </Button>
        <input
          ref={this.fileInputRef}
          type="file"
          hidden
          onChange={this.fileChange}
        />
        {errors.certificate && (
          <x>
            <br />
            <InlineError text={errors.certificate} />
          </x>
        )}
        <br />

        <Button className="signup" primary>
          {_t("Sign up")}
        </Button>

        <div className="loading">
          <span>loading...</span>
        </div>
      </Form>
    );
  }
}

SignupForm.propTypes = {
  submit: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: state.ui.language,
  options: state.options.data,
});

export default connect(mapStateToProps, { createNewUser, showMessage })(
  withRouter(SignupForm)
);
