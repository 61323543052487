export const de = {
  routes: [
    ["shop", "shop"],
    [`payment`, `payments`],
    [`otcs`, `otcs`],
    [`products`, `products`],
    ["company", "firma"],
    ["medicine", "medizin"],
    ["health", "gesundheit"],
    [`resources`, `ressourcen`],
    ["story", "geschichte"],
    [`garden`, `garten`],
    ["homeopathy", "homoeopathie"],
    ["gemmotherapy", "gemmotherapie"],
    ["phytotherapy", "kraeutermedizin"],
    ["oligotherapy", "spurenelemente"],
    ["values", "werte"],
    ["quality", "qualitaet"],
    ["team", "team"],
    ["lab", "labor"],
    ["schussler_salts", "schuessler_salze"],
    ["food_supplements", "nahrungsergaenzungsmittel"],
    ["make_your_own", "machen_sie_es_selber"],
    ["remedies", "mittel"],
    ["seasonal", "saisonal"],
    ["account", "konto"],
    ["security", "sicherheit"],
    ["addresses", "adressen"],
    ["orders", "bestellungen"],
    ["complexes", "komplexe"],
    ["login", "login"],
    ["imprint", "impressum"],
    ["contact", "kontakt"],
    ["signup", "signup"],
    ["checkout", "checkout"],
    ["news", "news"],
    ["payment", "payment"],
    ["privacy-policy", "datenschutzerklaerung"],
    [`where`, `wo_sie_unsere_heilmittel_finden`],
    [`training`, `ausbildung`],
    [`research`, `forschung`],
    [`foundation`, `stiftung`],
    [`associations`, `verbande`],
  ],
  menu: [
    [`Account`, `Konto`],
    [`Cart`, `Einkaufswagen`],
    [`Start`, `Start`],
    [`Search`, `Suche`],
    [`News`, `News`],
    [`Health`, `Ihre Gesundheit`],
    [`Food Supplements`, `Nahrungsergänzungsmittel`],
    [`Homeopathy`, `Homöopathie`],
    [`Phytotherapy`, `Kräutermedizin`],
    [`Oligotherapy`, `Spurenelemente`],
    [`Schussler Salts`, `Schüssler Salze`],
    [`Gemmotherapy`, `Gemmotherapie`],
    [`Story`, `Geschichte`],
    [`Garden`, `Botanischer Garten`],
    [`Values`, `Werte`],
    [`Quality`, `Qualität`],
    [`Team`, `Team`],
    [`Lab`, `Labor`],
    [`Make your Own`, `Machen Sie es selber`],
    [`Your Orders`, `Ihre Bestellungen`],
    [`Addresses`, `Adressen`],
    [`Medicine`, `Unsere Naturheilmittel`],
    [`Company`, `Unsere Labore`],
    [`Resources`, `Ressourcen`],
    [`Shop`, `Unsere Produkte`],
    [`Imprint`, `Impressum`],
    [`Contact`, `Kontakt`],
    [`Security`, `Sicherheit`],
    [`Login / Register`, `Anmelden / Registrieren`],
    [`Language`, `Language`],
    [`French`, `French`],
    [`SN Canada`, `SN Canada`],
    [`English`, `English`],
    [`German`, `German`],
    [`Italian`, `Italian`],
    [`Where to find our remedies`, `Wo Sie unsere Heilmittel finden`],
    [`Training`, `Ausbildung`],
    [`Research`, `Wissenschaftliche`],
    [`Foundation`, `Stiftung`],
    [`Associations`, `Verbände`],
  ],
  schussler_salts: [
    ["pl_6", "Tablette 6DH"],
    ["pl_12", "Tablette 12DH"],
    ["p_6", "Pulver 6DH"],
    ["p_12", "Pulver 12DH"],
    ["p", "Pulver"],
  ],
  professions: [
    ["pharmacist", "Apotheker"],
    ["therapeut", "Therapeut"],
    [
      "You can only sign up as a professional.",
      "Sie können sich nur als Therapeut oder Apotheker anmelden.",
    ],
    ["Please specify your Pharma Id.", "Bitte geben Sie Ihre Pharma Id an."],
  ],
  pages: [
    [`Resources`, `Ressourcen`],
    [`Company Values`, `Firma Werte`],
    [`Company Lab`, `Firma Labor`],
    [`Medicine Food Supplements`, `Medizin Nahrungsergänzungsmittel`],
    [`Shop Homeopathy`, `Shop Homöopathie`],
    [`Medicine Phytotherapy`, `Medizin Kräutermedizin`],
    [`Shop Gemmotherapy`, `Shop Gemmotherapie`],
    [`Shop Seasonal`, `Shop Saisonal`],
    [`Shop Phytotherapy`, `Shop Kräutermedizin`],
    [`Medicine Homeopathy`, `Medizin Homöopathie`],
    [`Shop Make your Own`, `Shop Machen Sie es selber`],
    [`Company Story`, `Firma Geschichte`],
    [`Shop Schussler Salts`, `Shop Schüssler Salze`],
    [`Shop Oligotherapy`, `Shop Spurenelemente`],
    [`Medicine Gemmotherapy`, `Medizin Gemmotherapie`],
    [`Medicine Schussler Salts`, `Medizin Schüssler Salze`],
    [`Home`, `Startseite`],
    [`Shop Homeopathy Remedies`, `Shop Homöopathie Mittel`],
    [`Health`, `Gesundheit`],
    [`Shop Food Supplements`, `Shop Nahrungsergänzungsmittel`],
    [`Medicine Oligotherapy`, `Medizin Spurenelement`],
    [`Science`, `Wissenschaft`],
    [`Shop Homepathy Complexes`, `Shop Homöopathie Komplexe`],
    [`Company Quality`, `Firma Qualität`],
    [`Company Team`, `Firma Team`],
  ],
  shop: [
    [`Avaliable in {nr} variations`, `Erhältlich in {nr} Varianten`],
    [`150ml`, `150ml`],
    [`250ml`, `250ml`],
    [`60ml`, `60ml`],
    [`General information`, `Allgemeine Informationen`],
    [`Shipping`, `Versand`],
    [`Select Option`, `Eine Option wählen`],
    [`Add to Cart`, `Zum Warenkorb hinzufügen`],
    [`Granules`, `Granulat`],
    [`Globuli`, `Globuli`],
    [`General`, `Generell`],
    [`Form`, `Form`],
    [`Drops`, `Tropfen`],
    [`Creme`, `Creme`],
    [`Trituration`, `Trituration`],
    [`Suppository`, `Zäpfli`],
    [`Type`, `Art`],
    [`Phytotherapy`, `Kräutermedizin`],
    [`Homeopathy`, `Homöopathie`],
    [`Gemmotherapy`, `Gemmotheraphie`],
    [`Substance`, `Substanz`],
    [`Type more to see results...`, `Tippen Sie mehr um Resultate zu sehen`],
    [`Scale`, `Skala`],
    [`DH`, `DH`],
    [`CH`, `CH`],
    [`Dilution`, `Verdünnung`],
    [`variations`, `Variationen`],
    [`Avaliable in `, `Erhältlich in `],
    [`Substances`, `Substanz`],
    [`Tube 4g`, `Röhrchen 4g`],
    [`Bottle 15g`, `Flasche 15g`],
    [`Size`, `Grösse`],
    [`Tube 1g`, `Röhrchen 1g`],
    [`LM`, `LM`],
    [`K`, `K`],
    [`Bottle 10g`, `Flasche 10g`],
    [`Mannit Bottle 60g`, `Mannit Flasche 60g`],
    [`Carrier`, `Träger`],
    [`NACL`, `NACL`],
    [`Bottle 30ml`, `Flasche 30ml`],
    [`Bottle 10ml`, `Flasche 10ml`],
    [`25° AETH.`, `25° AETH.`],
    [`12 Pieces Adults`, `12 Stück Erwachsene`],
    [`Water`, `Wasser`],
    [`variation`, `Variation`],
    [`12 Pieces Kids`, `12 Stück Kinder`],
    [`Lactose Bottle 60g`, `Flasche Laktose 60g`],
    [`E DIST.`, `E DIST.`],
    [`50g`, `50g`],
    [`Bottle 125ml`, `Flasche 125ml`],
    [`Bottle 60ml`, `Flasche 60ml`],
    [`Bottle 250ml`, `Flasche 250ml`],
    [`Custom Remedy`, `Personalisiertes Mittel`],
    [`Summary`, `Zusammenfassung`],
    [`Total: {nr} items`, `Gesamt: {nr} Produkte`],
    [`Total: {nr} item`, `Gesamt: {nr} Produkt`],
    [`Cart`, `Warenkorb`],
    [`Checkout`, `Zahlen`],
    [`Pills 6DH`, `Pille 6DH`],
  ],
  message: [
    ["loading...", "Wird geladen..."],
    [
      "Your password reset email has been sent.",
      "Ihre E-Mail zum Zurücksetzen des Passworts wurde gesendet.",
    ],
    [
      "Your session expired, please log in again.",
      "Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an.",
    ],
  ],
  general: [
    [`Profession`, `Beruf`],
    [`First and last name`, `Vor- und Nachname`],
    [
      `Name of pharmacy and customer number`,
      `Name der Apotheke / Drogerie und ggf. Kundennummer`,
    ],
    [`Phone number`, `Telefonnummer`],
    [`FPH or GLN number for credits`, `FPH- oder GLN-Nummer für Kredit`],
    [`Message`, `Nachricht`],
    [
      `Unfortunately you cannot order in the shop. Please use the order form that you can download on your account page.`,
      `Leider können Sie nicht über den Shop bestellen. Bitte verwenden Sie das Bestellformular, das Sie auf Ihrer Kontoseite herunterladen können.`,
    ],
    [
      `Unfortunately you cannot order in the shop. Please contact us to enable this function.`,
      `Leider können Sie nicht im Shop bestellen. Bitte kontaktieren Sie uns, um diese Funktion zu aktivieren.`,
    ],
    [`Privacy Policy`, `Datenschutzerklärung`],
    [`Manage my cookies`, `Cookies verwalten`],
    [`Accept All`, `Alle akzeptieren`],
    [`Necessary`, `Notwendige`],
    [`Preferences`, `Einstellungen`],
    [`Statistics`, `Statistik`],
    [`Marketing`, `Marketing`],
    [`Save and close`, `Speichern und schließen`],
    [
      `We use essential cookies to make our site work. We and partners also use cookies to personalise your experience and measure our site's performance.`,
      `Wir verwenden notwendige Cookies, damit unsere Website funktioniert. Wir und unsere Partner verwenden Cookies auch, um Ihre Erfahrung zu personalisieren und die Leistung unserer Website zu messen.`,
    ],
    [
      `Please log in or register to buy products.`,
      `Bitte melden Sie sich an oder registrieren Sie sich, um Produkte zu kaufen.`,
    ],
    [
      `The login information you provided is wrong.`,
      `Die Anmeldedaten sind nicht korrekt. Bitte versuchen Sie es erneut.`,
    ],
    [
      `Thank you for signing up. We'll inform you once your account has been activated.`,
      `Vielen Dank für Ihre Anmeldung. Wir informieren Sie, sobald Ihr Konto aktiviert wurde.`,
    ],
    [
      "You will receive an email once your account has been verified and activated.",
      "Sie erhalten eine E-Mail, sobald Ihr Konto überprüft und aktiviert wurde.",
    ],
    [`Account`, `Konto`],
    [`Sign Up`, `Registrierung`],
    [`Emails are not the same`, `E-Mail-Adressen sind nicht identisch`],
    [`Search {type}...`, `In {type} suchen...`],
    ["add", "hinzufügen"],
    ["or", "oder"],
    ["Back", "Zurück"],
    ["OTC:", "OTC:"],
    ["Substances", "Substanzen"],
    ["Change File", "Datei ändern"],
    ["Learn More", " Erfahre mehr "],
    ["Loading...", "Wird geladen..."],
    [`Edit`, ` Bearbeiten `],
    [`Shipping Address`, `Lieferadresse`],
    [`Billing Address`, `Rechnungsadresse`],
    [`View Item`, `Produkt anzeigen`],
    [`Back to Food Supplements`, `Zurück zu Nährungsergänzungsmittel`],
    [`Back to Schussler Salts`, `Zurück zu Schüssler Salze`],
    [`Back to OTCs`, `Zurück zu OTCs`],
    [`Back to News`, `Zurück zu News`],
    [`Back to Resources`, `Zurück zu Ressourcen`],
    [`Read more`, `Weiterlesen`],
    [`Phone`, `Telefon`],
    [`Email`, `Email`],
    [`Password`, `Passwort`],
    [`Save`, `speichern`],
    [`Shop`, `Shop`],
    [`Close`, `Schließen`],
    [`Delete`, `Löschen`],
    [
      `Do you want to delete this substance?`,
      `Möchten Sie diese Substanz löschen?`,
    ],
    [`Cancel`, `Abbrechen`],
    [
      `Granules are only available if there is at least one Homeopathy substance`,
      `Granulate sind nur verfügbar, wenn mindestens eine homöopathische Substanz vorhanden ist`,
    ],
    [
      `Globuli are only available if there is at least one Homeopathy substance`,
      `Globuli sind nur verfügbar, wenn mindestens eine homöopathische Substanz vorhanden ist`,
    ],
    [
      `Drops are only available up to 30CH`,
      `Tropfen existieren nur bis zu 30CH`,
    ],
    [`Please enter your profession`, `Bitte geben Sie Ihren Beruf ein`],
    [`Address 1`, `Adresse 1`],
    [`Shipping Address is different`, `Die Lieferadresse ist anders`],
    [`State`, `Kanton`],
    [`Postcode`, `Postleitzahl`],
    [`Last Name`, `Nachname`],
    [`Repeat Email`, `Email wiederholen`],
    [`First Name`, `Vorname`],
    [`Sign up`, `Registrieren`],
    [`Choose File`, `Eine Datei hochladen`],
    [`Repeat Password`, `Passwort wiederholen`],
    [`Company`, `Firma`],
    [`Address 2`, `Adresse 2`],
    [`City`, `Stadt`],
    [`Certificate`, `Kantonale Bewilligung zur Berufsausübung/Diplom`],
    [`General`, `Generell`],
    [`substances`, `Substanzen`],
    [`Remedies`, `Mittel`],
    [`Complexe`, `Komplex`],
    [
      `Please note, that by law we are not allowed to sell our products directly to end customers. Therefore Sign Up is only available to swiss therapists with practice authorization from the canton. This has to be proven by uploading a document that verifies you as a professional.`,
      `Bitte beachten Sie, dass wir gesetzlich nicht autorisiert sind direkt an Privatkunden zu verkaufen. Fur Apotheken ist keine Online-Bestellung im E-Shop mo'glich. Der Verkauf unserer Medikamente an Therapeuten ist abhangig von den verschiedenen schweizerischen Kantonen. Vielen Dank fur die Zusendung Ihrer kantonalen Bewilligung zur Berufsausubung oder einer Praxisbewilligung, sowie Ihr Diplom.`,
    ],
    [`Topic`, `Thema`],
    [`Composition`, `Zusammenstellung`],
    [`Posology`, `Posologie`],
    [`Back to Complexes`, `Zurück zu Komplexe`],
    [`Back to Health`, `Zurück zu Gesundheit`],
    [`Indication`, `Indikation `],
    [
      `Item has ben added to Cart!`,
      `Das Produkt wurde Ihrem Warenkorb hinzugefügt!`,
    ],
    [`Successfully logged out!`, `Abmeldung erfolgreich`],
    [`Successfully logged in!`, `Anmeldung erfolgreich`],
    [`Good Afternoon`, `Guten Tag`],
    [`Good Morning`, `Guten Morgen`],
    [`Good Evening`, `Guten Abend`],
    [`Good Night`, `Gute Nacht`],
    [`Pages`, `Seiten`],
    [`Gemmotherapy`, `Gemmotherapie`],
    [`Phytotherapy`, `Kräutermedizin`],
    [`Homeopathy`, `Homöopathie`],
    [`Oligotherapy`, `Spurenelemente`],
    [`Shipping Address is the same`, `Die Lieferadresse ist die selbe`],
    [`Invalid email`, `Die E-Mail-Ad­res­se ist ungültig`],
    [`Can't be blank`, `Kann nicht leer sein`],
    [
      `Your password has to be at least eight characters long`,
      `Das Passwort muss mindestens 8 Buchstaben lang sein.`,
    ],
    [`Please select a certificate.`, `Wählen Sie bitte ein Zertifikat.`],
    [
      `There are errors in the form, please check and try again.`,
      `Das Formular enthält Fehler. Bitte überprüfen Sie es und versuchen Sie es erneut`,
    ],
    [`Passwords are not the same`, `Die Passwörter sind verschieden`],
    [`Where to find our remedies`, `Wo Sie unsere Heilmittel finden`],
    [`Loading maps`, `Karten werden geladen`],
    [`Pharmacy`, `Apotheke`],
    [`Drugstore`, `Drogerie`],
    [`Doctor`, `Artz`],
    [`Homeopath`, `Homöopath`],
    [`Naturopath`, `Naturheilpraktikerin`],
    [`Dentist`, `Zahnartz`],
    [`Midwife`, `Hebamme`],
    [`Veterinarian`, `Tierarzt`],
    [`Other therapist`, `Andere Therapeutin`],
    ["Back to Home", "Zurück zur Startseite"],
  ],
  health: [["Other Health Topics", "Andere Gesundheitsthemen"]],
  ui: [
    ["ok", "ok"],
    ["Add to Cart", "Zum Warenkorb hinzufügen"],
    ["Search Phytotherapy...", "Kräutermedizin durchsuchen..."],
    ["Search Gemmotherapy...", "Gemmotherapie durchsuchen..."],
    ["Search Oligotherapy...", "Spurenelemente durchsuchen..."],
    ["Search Homeopathy...", "Homöopathie durchsuchen..."],
    ["Hide all {nr} remedies", "Alle {nr} Mittel verbergen"],
    ["Show all {nr} remedies", "Alle {nr} Mittel anzeigen"],
    ["Loading...", "Wird geladen..."],
  ],
  cart: [
    ["{percent}% Discount:", "{percent}% Rabatt:"],
    ["Cart", "Warenkorb"],
    ["Your cart is empty.", "Ihr Warenkorb ist leer."],
    ["Total", "Gesamt"],
    ["Granules", "Granulat"],
  ],
  resources: [["Other Resources", "Weitere Ressourcen"]],
  account: [
    [
      `To reset your password, please enter your email address.`,
      `Um Ihr Passwort zurückzusetzen, geben Sie bitte Ihre E-Mail Adresse ein.`,
    ],
    [
      `Ok, great! Let's set a new password:`,
      "Sehr gut. Legen Sie ein neues Passwort fest:",
    ],
    ["Set password", "Passwort festlegen"],
    ["Reset Password", "Passwort zurücksetzen"],
    ["Repeat Password", "Passwort wiederholen"],
    ["Customer", "Kunde"],
    ["Continue", "Weiter"],
    ["Logout", "Abmelden"],
    [`Address Line 1`, `Adresse Zeile 1`],
    [`Address Line 2`, `Adresse Zeile 2`],
    [`City`, `Stadt`],
    [`Postcode`, `Postleitzahl`],
    [`State`, `Kanton`],
    [`Country`, `Land`],
    [`First Name`, `Vorname`],
    [`Company`, `Firma`],
    [`Last Name`, `Nachname`],
    [`Old Password`, `Altes Passwort`],
    [`New Password`, `Neues Passwort`],
    [`Repeat New Password`, `Passwort wiederholen`],
    [`Administrator`, `Administrator`],
    [`Good Afternoon`, `Guten Nachmittag`],
    [`Change Status`, `Status ändern`],
    [`You're registered as a`, `Sie sind angelemdet als: `],
    [`Back to Account`, `Zurück zum Konto`],
    [`Account Orders`, `Bestellungen pro Konto`],
    [`Orders`, `Bestellungen `],
    [`Good Evening`, `Guten Abend`],
    [`Account Security`, `Konto Sicherheit`],
    [`Security`, `Sicherheit`],
    [`Account Addresses`, `Konto Adresse`],
    [`Addresses`, `Adressen`],
    [`Shipping Address`, `Lieferadresse`],
    [`Billing Address`, `Rechnungsadresse`],
    [`unknown`, `unbekannt`],
    [`Email`, `E-Mail`],
    [`Password`, `Passwort`],
    [`Forgot Password?`, `Passwort vergessen?`],
    [`Log In`, `Einloggen`],
    [`Sign Up`, `Registrieren`],
  ],
  countries: [
    [`Kyrgyzstan`, `Khirgistan`],
    [`Rwanda`, `Rwanda`],
    [`Equatorial Guinea`, `Aequatorialquinea`],
    [`Marshall Islands`, `Marshallinseln`],
    [`Iraq`, `Irak`],
    [`Italy`, `Italien`],
    [`Ivory Coast`, `Elfenbeinküste`],
    [`Georgia`, `Georgien`],
    [`Germany`, `Deutschland`],
    [`Falkland Islands`, `Falklandinseln`],
    [`Guinea`, `Guinea `],
    [`Guyana`, `Guyane`],
    [`Sri Lanka`, `Sri Lanka`],
    [`Turks and Caicos Islands`, `Turks und Caicosinseln`],
    [`Turkmenistan`, `Turkmenistan `],
    [`Jamaica`, `Jamaica `],
    [`Tuvalu`, `Tuvalu`],
    [`Lebanon`, `Libanon`],
    [`Latvia`, `Lettland `],
    [`Lesotho`, `Lesotho`],
    [`Liberia`, `Liberia `],
    [`South Africa`, `Südafrika `],
    [`Mongolia`, `Mongolei`],
    [`Montenegro`, `Montenegro`],
    [`Norway`, `Norwegen `],
    [`France`, `Frankreich`],
    [`Romania`, `Rumänien `],
    [`Iran`, `Iran`],
    [`Saint Martin (Dutch part)`, `Saint Martin `],
    [`Peru`, `Peru`],
    [`Saint Barthélemy`, `Saint Barthélémy `],
    [`Austria`, `Oesterreich `],
    [`Laos`, `Laos`],
    [`Antigua and Barbuda`, `Antigua und Barbuda `],
    [`Wallis and Futuna`, `Wallis und Futuna `],
    [`Bosnia and Herzegovina`, `Bosnien `],
    [`Tajikistan`, `Tadschikistan `],
    [`Myanmar`, `Myanmar`],
    [`Saint Lucia`, `Saint Lucie `],
    [`CuraÇao`, `Curaçao `],
    [`Saint Martin (French part)`, `Saint Martin`],
    [`Russia`, `Russland `],
    [`Serbia`, `Serbien `],
    [`Belau`, `Belau`],
    [`São Tomé and Príncipe`, `Sao Tomé und Principe `],
    [`Pakistan`, `Pakistan `],
    [`Australia`, `Australien `],
    [`Bahrain`, `Bahrain `],
    [`Senegal`, `Senegal `],
    [`Trinidad and Tobago`, `Trinidad und Tabago `],
    [`Namibia`, `Namibien `],
    [`Belarus`, `Russland `],
    [`British Virgin Islands`, `Britische Jungferninseln `],
    [`Congo (Brazzaville)`, `Kongo `],
    [`Nauru`, `Nauru `],
    [`Barbados`, `Barbados `],
    [`Nicaragua`, `Nicaragua `],
    [`Papua New Guinea`, `Papau- Neuguinea `],
    [`Lithuania`, `Litauen`],
    [`Reunion`, `Reunion `],
    [`Cuba`, `Cuba`],
    [`Afghanistan`, `Afghanistan `],
    [`Paraguay`, `Praguay`],
    [`Finland`, `Finnland `],
    [`Bangladesh`, `Bangladesh`],
    [`Brazil`, `Brasilien `],
    [`Eritrea`, `Eritrea `],
    [`Congo (Kinshasa)`, `Kongo `],
    [`Kuwait`, `Kuweit `],
    [`Norfolk Island`, `Norfolk Island `],
    [`Niger`, `Niger `],
    [`North Korea`, `Nord Korea `],
    [`Oman`, `Oman`],
    [`South Sudan`, `Süd Sudan`],
    [`South Georgia/Sandwich Islands`, `Südgeorgien / Sandwichinseln `],
    [`United Arab Emirates`, `Vereinigte Arabische Emiraten `],
    [`Svalbard and Jan Mayen`, `Spitzbergen und Jan Mayen`],
    [`South Korea`, `Süd Korea `],
    [`Western Samoa`, `West Samoa `],
    [`Angola`, `Angola `],
    [`Aruba`, `Aruba `],
    [`Azerbaijan`, `Aserbaidschan `],
    [`Bahamas`, `Bahamas `],
    [`Egypt`, `Aegypten `],
    [`Bouvet Island`, `Ile Bouvet`],
    [`Heard Island and McDonald Islands`, `Herz und Mc. Donals Insel`],
    [`Hong Kong`, `Hong Kong`],
    [`Kenya`, `Kenia`],
    [`Haiti`, `Haiti`],
    [`Kazakhstan`, `Kasachstan`],
    [`Libya`, `Libien `],
    [`Mayotte`, `Mayotte `],
    [`Saint Helena`, `Saint Helena `],
    [`Martinique`, `Martinique `],
    [`Tonga`, `Tonga `],
    [`Faroe Islands`, `Färöer Insel`],
    [`Mexico`, `Mexico `],
    [`Malaysia`, `Malaisien `],
    [`Cocos (Keeling) Islands`, `Cocos Inseln `],
    [`Palestinian Territory`, `Palästinien `],
    [`Panama`, `Panama`],
    [`Slovakia`, `Slovakien `],
    [`Cameroon`, `Kamerun `],
    [`Estonia`, `Estland `],
    [`Dominica`, `Dominique `],
    [`Czech Republic`, `Tschechische Republik `],
    [`Fiji`, `Fidschi`],
    [`Guatemala`, `Guatemala `],
    [`India`, `Indien `],
    [`Indonesia`, `Indonesien `],
    [`Liechtenstein`, `Lichtenstein `],
    [`Madagascar`, `Madagaskar `],
    [`Malawi`, `Malawien `],
    [`Macao S.A.R., China`, `Macau`],
    [`Qatar`, `Qatar`],
    [`Mauritius`, `Mauritius `],
    [`Cyprus`, `Zypern `],
    [`Luxembourg`, `Luxemburg `],
    [`Solomon Islands`, `Salomoneninsel `],
    [`Netherlands Antilles`, `Niederländiche Antillen `],
    [`Netherlands`, `Niederland `],
    [`Spain`, `Spanien `],
    [`Thailand`, `Thailand`],
    [`Guernsey`, `Guernsey`],
    [`Zambia`, `Sambia `],
    [`Jersey`, `Jersey`],
    [`Honduras`, `Honduras `],
    [`Algeria`, `Algerien `],
    [`Brunei`, `Brunei `],
    [`Bhutan`, `Bhutan`],
    [`Costa Rica`, `Costa Rica `],
    [`Benin`, `Benin`],
    [`French Polynesia`, `Französische Republik`],
    [`Dominican Republic`, `Dominikanische Republik`],
    [`Burundi`, `Burundi`],
    [`Antarctica`, `Antarktis`],
    [`Bulgaria`, `Bulgarien `],
    [`Albania`, `Albanien `],
    [`Republic of Ireland`, `Republik Irland `],
    [`Croatia`, `Kroatien `],
    [`Djibouti`, `Dschibuti `],
    [`Iceland`, `Island`],
    [`Gabon`, `Gabun`],
    [`Guinea-Bissau`, `Guinea Bissau`],
    [`Hungary`, `Ungarn`],
    [`Micronesia`, `Mikronesien `],
    [`Moldova`, `Moldawien `],
    [`Saint Vincent and the Grenadines`, `St. Vincent und die Grenadinen `],
    [`Saudi Arabia`, `Saudi-Arabien `],
    [`Belize`, `Belize`],
    [`Andorra`, `Andorra`],
    [`Bolivia`, `Bolivien`],
    [`Bonaire, Saint Eustatius and Saba`, `Bonaire Sint- Eustache und Saba `],
    [`French Southern Territories`, `Französiche Südterritorien `],
    [`Gambia`, `Gambia`],
    [`Sierra Leone`, `Sierra Leone `],
    [`Togo`, `Togo`],
    [`Niue`, `Niue`],
    [`Somalia`, `Somalien `],
    [`Tokelau`, `Tokelau`],
    [`Uruguay`, `Uruguay `],
    [`Venezuela`, `Venezuela `],
    [`Uzbekistan`, `Usbekistan`],
    [`Åland Islands`, `Aland Island`],
    [`Denmark`, `Dänmark`],
    [`Chad`, `Chad`],
    [`Belgium`, `Belgien `],
    [`China`, `China`],
    [`Seychelles`, `Seychellen `],
    [`Yemen`, `Jemen`],
    [`Israel`, `Israel `],
    [`New Zealand`, `Neuseeland `],
    [`Isle of Man`, `Isle of Man `],
    [`Montserrat`, `Montserrat`],
    [`Guadeloupe`, `Guadeloupe `],
    [`British Indian Ocean Territory`, `British Indian Ocean Territory`],
    [`Monaco`, `Monaco `],
    [`Greece`, `Griechenland `],
    [`Portugal`, `Portugal`],
    [`El Salvador`, `El Salvador`],
    [`Christmas Island`, `Weihnachtsinsel `],
    [`Nigeria`, `Nigeria`],
    [`Switzerland`, `Schweiz`],
    [`Sweden`, `Schweden`],
    [`Ecuador`, `Ecuador`],
    [`San Marino`, `San Marino`],
    [`Kiribati`, `Kiribati`],
    [`Mali`, `Mali`],
    [`Mauritania`, `Mauretanien`],
    [`Mozambique`, `Mosambik`],
    [`Tanzania`, `Tansania`],
    [`Timor-Leste`, `Timor-Leste`],
    [`Tunisia`, `Tunesien`],
    [`Uganda`, `Uganda`],
    [`United Kingdom (UK)`, `England `],
    [`Ukraine`, `Ukraine`],
    [`United States (US)`, `Verinigte Staaten `],
    [`Zimbabwe`, `Simbabwe`],
    [`Suriname`, `Suriname`],
    [`Singapore`, `Singapur`],
    [`Syria`, `Syrien `],
    [`Swaziland`, `Swasiland`],
    [`Argentina`, `Argentinien `],
    [`Western Sahara`, `Westsahara`],
    [`Vatican`, `Vatikan`],
    [`Comoros`, `Komoren`],
    [`Cook Islands`, `Cookinseln`],
    [`Vanuatu`, `Vanuatu`],
    [`Gibraltar`, `Gibraltar`],
    [`Ghana`, `Ghana`],
    [`Greenland`, `Grönland`],
    [`Canada`, `Kanada`],
    [`Taiwan`, `Taiwan `],
    [`Chile`, `Chili `],
    [`Cape Verde`, `Kap Verde`],
    [`Anguilla`, `Anguilla `],
    [`Pitcairn`, `Pitcairn`],
    [`Cambodia`, `Kambodscha`],
    [`Saint Pierre and Miquelon`, `Saint-Pierre et Miquelon`],
    [`Cayman Islands`, `Cayman Islands `],
    [`Jordan`, `Jordanien`],
    [`Poland`, `Polen`],
    [`Central African Republic`, `Zentralafrikanische Republik`],
    [`Japan`, `Japan`],
    [`Bermuda`, `Bermuda`],
    [`Malta`, `Malta`],
    [`Slovenia`, `Slowenien`],
    [`Philippines`, `Philippinen`],
    [`Saint Kitts and Nevis`, `St. Kitts und Nevis`],
    [`Maldives`, `Malediven`],
    [`Macedonia`, `Mazedonien`],
    [`Nepal`, `Nepal`],
    [`Morocco`, `Marokko `],
    [`Vietnam`, `Vietnam  `],
    [`Sudan`, `Sudan`],
    [`Colombia`, `Kolumbien `],
    [`Armenia`, `Armenien `],
    [`Turkey`, `Türkei`],
    [`Botswana`, `Botswana`],
    [`Burkina Faso`, `Burkina Faso`],
    [`New Caledonia`, `Neukaledonien`],
    [`Ethiopia`, `Aethiopien `],
    [`French Guiana`, `Französiche Guyana`],
    [`Grenada`, `Grenada`],
  ],
};
